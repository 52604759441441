// import Cookies from 'js-cookie'
// import Config from '@/config'
// const TokenKey = Config.TokenKey;

// export function getToken() {
//   return Cookies.get( TokenKey + localStorage.getItem("userId") )
// }

// export function setToken( token, rememberMe, username ) {
//   if ( rememberMe ) {
//     return Cookies.set( TokenKey + username, token, { expires: Config.tokenCookieExpires } )
//   } else {
//     return Cookies.set( TokenKey + username, token )
//   }
// }

// export function removeToken( username ) {
//   return Cookies.remove( TokenKey + username )
// }
// 3.0的代码
import Cookies from 'js-cookie'

let uuidToken = sessionStorage.getItem('cookieToken')
let TokenKey = 'x-access-token-'+ uuidToken

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token,id) {
  console.log(token,id,"token,id")
  sessionStorage.setItem('cookieToken', id)
  TokenKey = 'x-access-token-' + id
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}